// src/components/UpdateModal.js
import React, { useEffect, useState } from "react";

const UpdateModal = () => {
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    // Optionnel : ouvrir la modal automatiquement au chargement de la page
    setIsOpen(true);
  }, []);

  return (
    isOpen && (
      <div className="fixed inset-0 z-[1000] flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg p-8 w-11/12 max-w-md text-center shadow-lg">
          <h2 className="text-3xl font-bold mb-4 text-black uppercase">
            Nouvelle identité pour pizza cook !
          </h2>
          <p className="text-gray-700 mb-6">
            Bienvenue sur notre nouveau site !
            <br />
            <br />
            Le site complet sera accessible dès janvier 2025 sur l'adresse{" "}
            <a
              href="https://pizza-cook.fr"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              pizza-cook.fr
            </a>
            . Vous pouvez dès à présent parcourir le site et passer vos
            commandes.
          </p>
          <button
            style={{ fontFamily: "gothambold, sans-serif" }}
            className="bg-primary text-white py-2 px-4 rounded"
            onClick={closeModal}
          >
            Fermer
          </button>
        </div>
      </div>
    )
  );
};

export default UpdateModal;
