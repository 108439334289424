import React, { createContext, useState, useEffect } from "react";

export const ConsentContext = createContext(); // Export nommé

export const ConsentProvider = ({ children }) => {
  const [consent, setConsent] = useState(() => {
    const savedConsent = localStorage.getItem("cookieConsent");
    return savedConsent ? JSON.parse(savedConsent) : null;
  });

  useEffect(() => {
    if (consent !== null) {
      localStorage.setItem("cookieConsent", JSON.stringify(consent));
    } else {
      localStorage.removeItem("cookieConsent"); // Supprime le consentement s'il est nul
    }
  }, [consent]);

  return (
    <ConsentContext.Provider value={{ consent, setConsent }}>
      {children}
    </ConsentContext.Provider>
  );
};
