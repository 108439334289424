import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeaderInfos from "../../components/sections/HeaderInfos";
import PizzaCarrouselSection from "../../components/sections/PizzaCarrouselSection";
import PizzeriasSection from "../../components/sections/PizzeriasSection";
import EventStockBanner from "../../components/sections/banners/EventStockBanner";
import GroupDistribBanner from "../../components/sections/banners/GroupDistribBanner";
import MetaTagsMaster from "../../components/utils/MetaTagsMaster";
import {
  ColorModeContext,
  WidthContext,
} from "../../components/utils/Providers";

export default function Distributors() {
  const { width } = useContext(WidthContext);
  const { setColor } = useContext(ColorModeContext);
  const navigate = useNavigate();

  const handleClick = (ev) => {
    if (ev.target.name === "menu") {
      navigate("/notre-carte");
    }
  };

  useEffect(() => {
    setColor("white");
  }, []);

  return (
    <>
      <MetaTagsMaster
        title="Nos distributeurs"
        description="Pizza Cook met à votre disposition des distributeurs disponibles 24h/24 avec un large choix de pizzas."
        keywords="distributeurs, pizzas, pizza cook, restaurant, france, saint sylvain, seiches, anjou"
      />
      <HeaderInfos
        title="Nos distributeurs"
        description="Vos pizzas fraîches artisanales au plus près de chez vous "
        option="distrib"
        width={width}
      />
      <GroupDistribBanner
        title="Déguster nos pizzas n'importe où"
        firstDescription="Toutes nos pizzas sont élaborées de façon artisanale dans nos pizzerias de Saint-Sylvain-d'Anjou et Seiches-sur-le-Loir puis mises à disposition dans nos bornes libre-service."
        secondDescription="Découvrez un large choix de pizzas sur nos distributeurs autonomes !"
      />
      <PizzaCarrouselSection onClick={handleClick} />
      <EventStockBanner
        title="Regardez le stock en temps réel"
        description="Nos machines à pizzas sont réapprovisionnées tous les jours. Regardez le stock en temps réel avec l'application SmartPizza"
        value="Télécharger l'application"
        name="app"
        onClick={() => {
          const isApple = /iPhone|iPad|iPod|Macintosh/i.test(
            navigator.userAgent
          );
          const url = isApple
            ? "https://apps.apple.com/fr/app/smart-pizza/id1603849374"
            : "https://play.google.com/store/apps/details?id=com.apitech.smart_pizza&hl=fr";

          window.open(url, "_blank");
        }}
      />
      {/* <ImgBanner option="distrib" /> */}
      <PizzeriasSection width={width} />
    </>
  );
}
