import MainLayout from "../components/layouts/MainLayout";
import CompanyDelivery from "../pages/public/CompanyDelivery";
import Distributors from "../pages/public/Distributors";
import Landing from "../pages/public/Landing";
import LegalsTerms from "../pages/public/LegalsTerms";
import PizzaCook from "../pages/public/PizzaCook";
import PizzaDetails from "../pages/public/PizzaDetails";
import PizzaMenu from "../pages/public/PizzaMenu";
import PrivacyPolicy from "../pages/public/PrivacyPolicy";
import Contact from "../pages/public/Contact";

export const MainRoutes = {
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Landing />,
    },
    {
      path: "/pizza-cook",
      element: <PizzaCook />,
    },
    {
      path: "/notre-carte",
      element: <PizzaMenu />,
    },
    {
      path: "/notre-carte/:slug",
      element: <PizzaDetails />,
    },
    {
      path: "/nos-distributeurs-24-24",
      element: <Distributors />,
    },
    {
      path: "/livraison-aux-entreprises",
      element: <CompanyDelivery />,
    },
    {
      path: "/mentions-legales",
      element: <LegalsTerms />,
    },
    {
      path: "/politique-confidentialite",
      element: <PrivacyPolicy />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
  ],
};
