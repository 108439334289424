import { motion } from "framer-motion";
import { useContext, useRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FullGreyDesk from "../assets/img/full-grey-desk.png";
import FullGreyDeskWebp from "../assets/img/full-grey-desk.webp";
import Logo from "../assets/img/logo-pizzacook.png";
import LogoWebp from "../assets/img/logo-pizzacook.webp";
import CtaBtn from "./buttons/CtaBtn";
import { deleteCookie } from "../utils/CheckCookie";
import { WidthContext } from "./utils/Providers";

export default function Footer({ toggle }) {
  const { width } = useContext(WidthContext);
  const navigate = useNavigate();
  const footerRef = useRef(null);
  const [footerTop, setFooterTop] = useState(0);
  const [isAboveFooter, setIsAboveFooter] = useState(true);

  const handleClick = () => {
    navigate("/");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (footerRef.current) {
        const footerPosition = footerRef.current.getBoundingClientRect().top;
        const buttonPosition = window.innerHeight - 100; // Ajuster 100 si nécessaire pour définir la limite du bouton
        setIsAboveFooter(buttonPosition < footerPosition);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check on load
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="relative">
        <CtaBtn isAboveFooter={isAboveFooter} />
        <footer ref={footerRef} className="bg-red relative">
          <div className="container mx-auto">
            <div className="flex justify-between items-center lg:flex-row flex-col lg:gap-0 gap-8">
              <div className="logo-container flex justify-center flex-col items-center lg:items-start">
                <motion.picture
                  whileHover={{
                    scale: 1.05,
                  }}
                  transition={{
                    duration: 0.5,
                    ease: "backInOut",
                  }}
                >
                  <source srcSet={LogoWebp} type="image/webp" />
                  <source srcSet={Logo} type="image/png" />
                  <img
                    src={Logo}
                    alt="Logo Pizza Cook"
                    className="logo-pizza cursor-pointer"
                    onClick={handleClick}
                    loading="lazy"
                  />
                </motion.picture>
                <div className="relative z-10 pt-[32px]">
                  <h3 className="text-white mb-[16px] lg:text-left text-center uppercase mt-[16px] lg:mt-0 pb-[24px]">
                    Nos pizzerias
                  </h3>
                  <div className="flex lg:flex-col lg:gap-0 flex-col gap-0 md:flex-row md:gap-4">
                    <div className="lg:text-left text-center">
                      <p className="text-white">
                        16 rue Emmanuel Voisin <br />
                        49480 Saint-Sylvain-D'Anjou
                      </p>
                      <a
                        href="tel:+33241954053"
                        className="bold text-white mt-[8px] block"
                      >
                        02 41 95 40 53
                      </a>
                      <br />
                    </div>
                    <br />
                    <div className="lg:text-left text-center">
                      <p className="text-white">
                        58 rue Nationale
                        <br />
                        49140 Seiches-Sur-Le-Loir
                      </p>
                      <a
                        href="tel:+33241761082"
                        className="bold text-white mt-[8px] block"
                      >
                        02 41 76 10 82
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative z-10 md:pt-[0] pt-[64px]">
                <div className="">
                  <ul className="first-links-list flex flex-col uppercase items-center text-white">
                    <li>
                      <Link to="/pizza-cook">Pizza cook</Link>
                    </li>
                    <li>
                      <Link to="/notre-carte" className="min-w-[154px]">
                        La carte
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="relative z-10">
                <ul className="second-links-list flex">
                  <motion.li
                    initial={{
                      opacity: 0,
                      scale: 0.5,
                    }}
                    whileInView={{
                      scale: 1,
                      opacity: 1,
                    }}
                    viewport={{ once: true, amount: 0.3 }}
                    transition={{
                      duration: 1.5,
                      ease: "backInOut",
                    }}
                    whileHover={{
                      rotate: 360,
                    }}
                    className="second-links-item"
                    loading="lazy"
                  >
                    <Link
                      to={"https://www.facebook.com/pizzacook.fr"}
                      target="_blank"
                      aria-label="Facebook"
                    >
                      <svg
                        width="11"
                        height="18"
                        viewBox="0 0 11 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 0.505913C11 0.373305 10.9473 0.246128 10.8536 0.15236C10.7598 0.0585913 10.6326 0.00591282 10.5 0.00591282H8C6.74112 -0.0567963 5.5086 0.381304 4.57172 1.22449C3.63485 2.06768 3.06978 3.24741 3 4.50591V7.20591H0.5C0.367392 7.20591 0.240215 7.25859 0.146447 7.35236C0.0526785 7.44613 0 7.5733 0 7.70591V10.3059C0 10.4385 0.0526785 10.5657 0.146447 10.6595C0.240215 10.7532 0.367392 10.8059 0.5 10.8059H3V17.5059C3 17.6385 3.05268 17.7657 3.14645 17.8595C3.24021 17.9532 3.36739 18.0059 3.5 18.0059H6.5C6.63261 18.0059 6.75979 17.9532 6.85355 17.8595C6.94732 17.7657 7 17.6385 7 17.5059V10.8059H9.62C9.73119 10.8075 9.83974 10.772 9.92848 10.705C10.0172 10.638 10.0811 10.5433 10.11 10.4359L10.83 7.83591C10.8499 7.76203 10.8526 7.68458 10.8378 7.6095C10.8231 7.53443 10.7913 7.46374 10.7449 7.40288C10.6985 7.34203 10.6388 7.29262 10.5704 7.25847C10.5019 7.22432 10.4265 7.20634 10.35 7.20591H7V4.50591C7.02487 4.25839 7.14111 4.02902 7.32602 3.86261C7.51093 3.69619 7.75123 3.60467 8 3.60591H10.5C10.6326 3.60591 10.7598 3.55323 10.8536 3.45947C10.9473 3.3657 11 3.23852 11 3.10591V0.505913Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                  </motion.li>
                  <motion.li
                    initial={{
                      opacity: 0,
                      scale: 0.5,
                    }}
                    whileInView={{
                      scale: 1,
                      opacity: 1,
                    }}
                    viewport={{ once: true, amount: 0.3 }}
                    transition={{
                      duration: 1.5,
                      ease: "backInOut",
                    }}
                    whileHover={{
                      rotate: 360,
                    }}
                    className="second-links-item"
                    loading="lazy"
                  >
                    <Link
                      to="https://www.instagram.com/pizzacook49/"
                      target="_blank"
                      aria-label="Instagram"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.8 0H14.2C17.4 0 20 2.6 20 5.8V14.2C20 15.7383 19.3889 17.2135 18.3012 18.3012C17.2135 19.3889 15.7383 20 14.2 20H5.8C2.6 20 0 17.4 0 14.2V5.8C0 4.26174 0.61107 2.78649 1.69878 1.69878C2.78649 0.61107 4.26174 0 5.8 0ZM5.6 2C4.64522 2 3.72955 2.37928 3.05442 3.05442C2.37928 3.72955 2 4.64522 2 5.6V14.4C2 16.39 3.61 18 5.6 18H14.4C15.3548 18 16.2705 17.6207 16.9456 16.9456C17.6207 16.2705 18 15.3548 18 14.4V5.6C18 3.61 16.39 2 14.4 2H5.6ZM15.25 3.5C15.5815 3.5 15.8995 3.6317 16.1339 3.86612C16.3683 4.10054 16.5 4.41848 16.5 4.75C16.5 5.08152 16.3683 5.39946 16.1339 5.63388C15.8995 5.8683 15.5815 6 15.25 6C14.9185 6 14.6005 5.8683 14.3661 5.63388C14.1317 5.39946 14 5.08152 14 4.75C14 4.41848 14.1317 4.10054 14.3661 3.86612C14.6005 3.6317 14.9185 3.5 15.25 3.5ZM10 5C11.3261 5 12.5979 5.52678 13.5355 6.46447C14.4732 7.40215 15 8.67392 15 10C15 11.3261 14.4732 12.5979 13.5355 13.5355C12.5979 14.4732 11.3261 15 10 15C8.67392 15 7.40215 14.4732 6.46447 13.5355C5.52678 12.5979 5 11.3261 5 10C5 8.67392 5.52678 7.40215 6.46447 6.46447C7.40215 5.52678 8.67392 5 10 5ZM10 7C9.20435 7 8.44129 7.31607 7.87868 7.87868C7.31607 8.44129 7 9.20435 7 10C7 10.7956 7.31607 11.5587 7.87868 12.1213C8.44129 12.6839 9.20435 13 10 13C10.7956 13 11.5587 12.6839 12.1213 12.1213C12.6839 11.5587 13 10.7956 13 10C13 9.20435 12.6839 8.44129 12.1213 7.87868C11.5587 7.31607 10.7956 7 10 7Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                  </motion.li>
                </ul>
              </div>
            </div>
            <div className="flex justify-center lg:pt-[24px] pt-[64px]">
              <ul className="third-links-list flex flex-col items-center text-white">
                <li className="text-center">
                  {/* <button
                  onClick={() => {
                    deleteCookie("cookieConsent");
                    window.location.reload();
                  }}
                  className="text-white cursor-pointer no-underline hover:underline regular text-sm"
                  title="Conditions générales de vente"
                >
                  Cookies
                </button> */}
                  <Link
                    onClick={() => {
                      deleteCookie("cookieConsent");
                      window.location.reload();
                    }}
                  >
                    Utilisation des cookies
                  </Link>
                </li>
                <li className="text-center">
                  <Link to="/mentions-legales">Mentions légales</Link>
                </li>
                <li className="text-center">
                  <Link to="/politique-confidentialite">
                    Politique de confidentialité
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="absolute top-0 right-0 opacity-30 hidden lg:block">
            <picture>
              <source srcSet={FullGreyDeskWebp} type="image/webp" />
              <source srcSet={FullGreyDesk} type="image/png" />
              <motion.img
                initial={{
                  y: width >= 1024 ? 0 : 100,
                  rotate: width >= 1024 ? 0 : 90,
                  x: width >= 1024 ? 100 : 0,
                  opacity: 0,
                }}
                whileInView={{
                  y: width >= 1024 ? 0 : 0,
                  rotate: width >= 1024 ? 0 : 90,
                  x: width >= 1024 ? 0 : 0,
                  opacity: 1,
                }}
                viewport={{ once: true, amount: 0.1 }}
                transition={{
                  duration: 1.5,
                  ease: "backInOut",
                }}
                src={FullGreyDesk}
                alt=""
                className="full-grey-desk"
                loading="lazy"
              />
            </picture>
          </div>
        </footer>
      </div>
    </>
  );
}
