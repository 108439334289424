// src/components/OrderButton.js
import React, { useContext, useState } from "react";
import { WidthContext } from "../../components/utils/Providers";

export default function CtaBtn({ isAboveFooter }) {
  const { width } = useContext(WidthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOrderClick = (ev) => {
    if (ev.target.name === "phone") {
      // Si l'écran est supérieur à 420px, ouvrir la modal, sinon rediriger vers le numéro de téléphone
      if (window.innerWidth > 768) {
        setIsModalOpen(true);
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {width > 768 ? (
        <>
          <button
            style={{
              position: isAboveFooter ? "fixed" : "absolute",
              bottom: isAboveFooter ? "2rem" : "auto",
              // marginTop: isAboveFooter ? "0" : "-100px",
              top: isAboveFooter ? "auto" : "-5.3rem",
              right: "20px",
              padding: "10px 20px",
              backgroundColor: "#ba1817",
              color: "#FFF",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              zIndex: 1000,
              fontFamily: "gothambold, sans-serif",
              display: "flex",
              alignItems: "center",
            }}
            onClick={handleOrderClick}
            name="phone"
          >
            Passer commande
            <svg
              style={{ pointerEvents: "none" }}
              className="pl-[8px]"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="40" height="40" rx="8" fill="#1D1D1B" />
              <path
                d="M23.3592 22.906L22.9295 23.359C22.9295 23.359 21.9066 24.435 19.1158 21.497C16.325 18.559 17.3478 17.483 17.3478 17.483L17.6179 17.197C18.2857 16.495 18.3489 15.367 17.7662 14.543L16.5762 12.86C15.8547 11.84 14.4616 11.705 13.6352 12.575L12.1525 14.135C11.7435 14.567 11.4696 15.125 11.5027 15.745C11.5877 17.332 12.2658 20.745 16.0473 24.727C20.0584 28.949 23.8219 29.117 25.3604 28.965C25.8478 28.917 26.2709 28.655 26.6118 28.295L27.9529 26.883C28.8596 25.93 28.6046 24.295 27.4448 23.628L25.6409 22.589C24.8797 22.151 23.9542 22.28 23.3592 22.906Z"
                fill="white"
              />
            </svg>
          </button>
          {isModalOpen && (
            <div className="modal-overlay" style={{ zIndex: 9999 }}>
              <div className="modal-content relative">
                <div
                  className="absolute top-[-15px] right-[-15px] cursor-pointer bg-red text-white p-2 rounded-full"
                  role="button"
                  aria-label="Fermer"
                  onClick={closeModal} // Add close button handler to close menu
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </div>
                <h3 className="text-4xl text-black">Saint-Sylvain-D'Anjou</h3>
                <a
                  href="tel:+33241954053"
                  className="text-black pt-2 text-xl pb-[16px] block"
                >
                  02 41 95 40 53
                </a>
                <br />
                <h3 className="text-4xl text-black">Seiches-Sur-Le-Loir</h3>
                <a
                  href="tel:+33241761082"
                  className="text-black pt-2 text-xl block"
                >
                  02 41 76 10 82
                </a>
              </div>
            </div>
          )}
        </>
      ) : null}
    </>
  );
}
