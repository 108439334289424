import React, { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";
import cookieIcon from "../assets/img/cookie.svg";
import { ConsentContext } from "../context/ConsentContext";

export default function CookiesBanner() {
  // Vérifier si la bannière doit être affichée en fonction de la présence d'un consentement
  const { consent, setConsent } = useContext(ConsentContext);
  const [showModal, setShowModal] = useState(!consent && !navigator.brave);

  const [showDetails, setShowDetails] = useState(false);
  const [defaultConsent, setDefaultConsent] = useState({
    analytics_storage: false,
    ad_storage: false,
    ad_user_data: false,
    ad_personalization: false,
    personalization_storage: false,
    functionality_storage: true, // Les fonctionnalités de base sont toujours activées
    security_storage: true, // Les fonctionnalités de sécurité sont toujours activées
  });

  useEffect(() => {
    if (!consent && !navigator.brave) {
      setShowModal(true);
    }
  }, [consent]);

  useEffect(() => {
    if (showModal) {
      document.documentElement.classList.add("noScroll");
    } else {
      document.documentElement.classList.remove("noScroll");
    }
  }, [showModal]);

  const acceptAllCookies = () => {
    const allConsents = {
      ...defaultConsent,
      analytics_storage: true,
      ad_storage: true,
      ad_user_data: true,
      ad_personalization: true,
      personalization_storage: true,
    };
    setConsent(allConsents);
    setShowModal(false);
  };

  const rejectAllCookies = () => {
    const noConsents = {
      ...defaultConsent,
      analytics_storage: false,
      ad_storage: false,
      ad_user_data: false,
      ad_personalization: false,
      personalization_storage: false,
    };
    setConsent(noConsents);
    setShowModal(false);
  };

  function handleDetails() {
    setShowDetails(!showDetails);
  }

  function saveDetails() {
    setConsent(defaultConsent);
    setShowModal(false);
  }

  if (!showModal) return null;

  return createPortal(
    <>
      {showModal && (
        <div className="fixed h-full w-full top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-black bg-opacity-80 z-[10000] flex items-center justify-center overflow-auto">
          <div className="bg-white max-h-[80%] w-[90%] md:w-[80%] lg:w-[50%] flex flex-col items-center rounded-[20px] p-[35px] gap-[20px] overflow-auto">
            {!showDetails ? (
              <>
                <div className="flex items-center gap-[15px]">
                  <img
                    className="h-[25px] animate-bounce "
                    src={cookieIcon}
                    alt="Icon de cookie"
                    loading="lazy"
                    width={25}
                    height={25}
                  />
                  <h3 className="text-3xl text-center text-black">
                    Respect de la vie privée
                  </h3>
                  <img
                    className="h-[25px] animate-bounce"
                    src={cookieIcon}
                    alt="Icon de cookie"
                    loading="lazy"
                    width={25}
                    height={25}
                  />
                </div>
                <p className="text-center text-black">
                  Nous utilisons des cookies sur notre site Web dans le but
                  d'améliorer continuellement votre expérience. Pour plus
                  d'informations, veuillez consulter notre{" "}
                  <Link
                    target="_blank"
                    to="/politique-confidentialite"
                    className="underline"
                    title="Page de confidentialité"
                  >
                    Politique de confidentialité
                  </Link>
                  .
                </p>
                <p className="text-center text-black">
                  À l'exception des cookies indispensables à la navigation sur
                  notre site, nous n'utilisons ces cookies et ne partageons vos
                  données qu'avec votre consentement.
                </p>
                <div className="text-black flex flex-col gap-[10px] min-w-[50%]">
                  <button
                    className="underline p-[10px]"
                    onClick={() => rejectAllCookies()}
                  >
                    Tout refuser
                  </button>
                  <button
                    className="bg-base-100 text-white font-bold shadow-md p-[20px] rounded-[20px] min-w-[50%]"
                    onClick={() => handleDetails()}
                  >
                    Définir les préférences
                  </button>
                  <button
                    className="bg-primary text-white font-bold shadow-md p-[20px] rounded-[20px] min-w-[50%]"
                    onClick={() => acceptAllCookies()}
                  >
                    Tout accepter
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="w-full justify-between items-center flex gap-[15px]">
                  <button
                    className="text-2xl text-black"
                    onClick={() => handleDetails()}
                  >
                    &larr;
                  </button>
                  <div className="flex items-center justify-between gap-[15px]">
                    <img
                      className="h-[25px] animate-bounce"
                      src={cookieIcon}
                      alt="Icon de cookie"
                      loading="lazy"
                      width={25}
                      height={25}
                    />
                    <h3 className="text-3xl text-black">Paramétrer</h3>
                    <img
                      className="h-[25px] animate-bounce"
                      src={cookieIcon}
                      alt="Icon de cookie"
                      loading="lazy"
                      width={25}
                      height={25}
                    />
                  </div>
                  <div></div>
                </div>
                {/* Cookies nécessaires */}
                <div className="flex gap-[35px] items-center text-black">
                  <p>
                    Ces cookies sont indispensables pour la navigation et le bon
                    fonctionnement de notre site. Ils permettent des fonctions
                    de base comme la sécurité, la gestion du réseau, et
                    l'accessibilité. Ils ne peuvent pas être désactivés.
                  </p>
                  <input
                    type="checkbox"
                    className="toggle toggle-primary"
                    defaultChecked={true}
                    checked={true}
                    disabled
                  />
                </div>

                {/* Personnalisation et contenu */}
                <div className="flex gap-[35px] items-center text-black">
                  <p>
                    Acceptez-vous l’utilisation de cookies pour personnaliser le
                    contenu et les annonces, offrir des fonctionnalités de
                    médias sociaux et analyser notre trafic ? Cela nous permet
                    d'offrir une expérience plus personnalisée.
                  </p>
                  <input
                    type="checkbox"
                    className="toggle toggle-primary"
                    checked={defaultConsent.personalization_storage}
                    onChange={() =>
                      setDefaultConsent({
                        ...defaultConsent,
                        personalization_storage:
                          !defaultConsent.personalization_storage,
                      })
                    }
                  />
                </div>

                {/* Analytique */}
                <div className="flex gap-[35px] items-center text-black">
                  <p>
                    Ces cookies nous aident à comprendre comment notre site est
                    utilisé et comment nous pouvons améliorer votre expérience.
                    Ces cookies collectent des informations de manière anonyme.
                  </p>
                  <input
                    type="checkbox"
                    className="toggle toggle-primary"
                    checked={defaultConsent.analytics_storage}
                    onChange={() =>
                      setDefaultConsent({
                        ...defaultConsent,
                        analytics_storage: !defaultConsent.analytics_storage,
                      })
                    }
                  />
                </div>

                {/* Publicité ciblée */}
                <div className="flex gap-[35px] items-center text-black">
                  <p>
                    Ces cookies sont utilisés pour afficher des publicités
                    personnalisées basées sur vos centres d’intérêt. Refuser ces
                    cookies n'affectera pas la navigation mais peut rendre les
                    annonces moins pertinentes.
                  </p>
                  <input
                    type="checkbox"
                    className="toggle toggle-primary"
                    checked={defaultConsent.ad_storage}
                    onChange={() =>
                      setDefaultConsent({
                        ...defaultConsent,
                        ad_storage: !defaultConsent.ad_storage,
                      })
                    }
                  />
                </div>
                <p className=" text-black">
                  Pour en savoir plus sur les cookies utilisés par nos
                  partenaires et les données qu’ils collectent, veuillez les
                  contacter directement ou consulter leur politique de
                  confidentialité.
                </p>
                <button
                  className="bg-primary text-white font-bold shadow-md p-[20px] rounded-[20px] min-w-[50%]"
                  onClick={() => saveDetails()}
                >
                  Enregistrer
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </>,
    document.getElementById("root")
  );
}
