import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MushroomWhite from "../../assets/img/mushroom-white.png";
import MushroomWhiteWebp from "../../assets/img/mushroom-white.webp";
import PizzaWhite from "../../assets/img/pizza-white.png";
import PizzaWhiteWebp from "../../assets/img/pizza-white.webp";
import SliceWhite from "../../assets/img/slice-white.png";
import SliceWhiteWebp from "../../assets/img/slice-white.webp";
import TomatoWhite from "../../assets/img/tomato-white.png";
import TomatoWhiteWebp from "../../assets/img/tomato-white.webp";
import PizzaCard from "../../components/cards/PizzaCard";
import SearchBar from "../../components/inputs/SearchBar";
import ContactSection from "../../components/sections/ContactSection";
import HeaderInfos from "../../components/sections/HeaderInfos";
import PizzeriasSection from "../../components/sections/PizzeriasSection";
import SlidingMessage from "../../components/sections/SlidingMessage";
import MetaTagsMaster from "../../components/utils/MetaTagsMaster";
import { PizzasList } from "../../components/utils/PizzasList";
import {
  ColorModeContext,
  ToggleContext,
  WidthContext,
} from "../../components/utils/Providers";

export default function PizzaMenu() {
  const { width } = useContext(WidthContext);
  const { setColor } = useContext(ColorModeContext);
  const { toggle } = useContext(ToggleContext);
  const [display, setDisplay] = useState("list");
  const [filteredPizzas, setFilteredPizzas] = useState(PizzasList);
  const navigate = useNavigate();
  const [searchInputValue, setSearchInputValue] = useState("");
  const phoneNumber = !toggle ? "02 41 95 40 53" : "02 41 76 10 82";

  const handleClick = (ev) => {
    if (ev.target.name === "contact") {
      navigate("/contact");
    } else if (ev.target.name === "show-menu") {
      document
        .querySelector("#pizzas-section")
        .scrollIntoView({ behavior: "smooth" });
    }
  };

  const showDetails = (slug) => {
    setDisplay("details");
    navigate("/notre-carte/" + slug);
  };

  const handleDisplay = (ev) => {
    if (ev.target.name === "mosaic") {
      setDisplay("mosaic");
    } else if (ev.target.name === "list") {
      setDisplay("list");
    }
  };

  const handleInput = (ev) => {
    const searchValue = ev.target.value.toLowerCase();
    setSearchInputValue(searchValue);
    const newPizzasList = PizzasList.map((pizzaSection) =>
      pizzaSection.filter((pizza) =>
        pizza.name.toLowerCase().includes(searchValue)
      )
    );
    setFilteredPizzas(newPizzasList);
  };

  useEffect(() => {
    width >= 768 ? setColor("white") : setColor("black");
  }, [width]);

  return (
    <>
      <MetaTagsMaster
        title="La carte"
        description="Nous vous proposons une grande variété de pizzas pour satisfaire tous les goûts, des grands aux plus petits, avec notre gamme destinée aux Loulous."
        keywords="carte, menu, pizzas, pizza cook, restaurant, france, saint sylvain, seiches, anjou"
      />
      <HeaderInfos
        title="La carte"
        description="Nous vous proposons une grande variété de pizzas pour satisfaire tous les goûts, des grands aux plus petits, avec notre gamme destinée aux Loulous."
        option="menu"
        width={width}
        onClick={handleClick}
      />
      {width >= 768 && <SlidingMessage color="beige" />}
      {width >= 1024 && (
        <div className="top-imgs-container flex justify-between w-full">
          <div className="mushroom-white-container">
            <picture className="mushroom-white">
              <source srcSet={MushroomWhiteWebp} type="image/webp" />
              <source srcSet={MushroomWhite} type="image/png" />
              <motion.img
                initial={{
                  x: width >= 768 ? -100 : -25,
                  opacity: 0,
                }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{
                  duration: 1.5,
                  ease: "backInOut",
                }}
                src={MushroomWhite}
                alt=""
                loading="lazy"
              />
            </picture>
          </div>
          <div className="pizza-white-container">
            <picture className="pizza-white">
              <source srcSet={PizzaWhiteWebp} type="image/webp" />
              <source srcSet={PizzaWhite} type="image/png" />
              <motion.img
                initial={{
                  rotate: 0,
                  scale: 0,
                  opacity: 0,
                }}
                whileInView={{
                  rotate: 360,
                  scale: 1,
                  opacity: 1,
                }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{
                  duration: 1.5,
                  ease: "backInOut",
                }}
                src={PizzaWhite}
                alt=""
                loading="lazy"
              />
            </picture>
          </div>
        </div>
      )}
      <section
        className={
          filteredPizzas[0].length > 0
            ? "normal-pizza-section flex flex-col"
            : "normal-pizza-section-bis flex flex-col"
        }
        id="pizzas-section"
      >
        <h2 className="sr-only">Liste des pizzas</h2>
        <SearchBar
          onClick={handleDisplay}
          display={display}
          onInput={handleInput}
          width={width}
        />
        <div
          className={
            display === "mosaic"
              ? "mosaic-pizza-container grid grid-cols-2 gap-4"
              : "list-pizza-container flex flex-col "
          }
        >
          {filteredPizzas[0].map((pizza, i) => (
            <PizzaCard
              key={i}
              pizza={pizza}
              display={display}
              onClick={() => showDetails(pizza.slug)}
            />
          ))}
        </div>
      </section>
      {width >= 1024 && searchInputValue === "" ? (
        <div className="bottom-imgs-container flex justify-between w-full">
          <div className="slice-white-container">
            <picture className="slice-white">
              <source srcSet={SliceWhiteWebp} type="image/webp" />
              <source srcSet={SliceWhite} type="image/png" />
              <motion.img
                initial={{
                  opacity: 0,
                  scale: 0.5,
                }}
                whileInView={{
                  scale: 1,
                  opacity: 1,
                }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{
                  duration: 1.5,
                  ease: "backInOut",
                }}
                src={SliceWhite}
                alt=""
                loading="lazy"
              />
            </picture>
          </div>
          <div className="tomato-white-container place-self-end">
            <picture className="tomato-white">
              <source srcSet={TomatoWhiteWebp} type="image/webp" />
              <source srcSet={TomatoWhite} type="image/png" />
              <motion.img
                initial={{
                  opacity: 0,
                  scale: 0.5,
                }}
                whileInView={{
                  scale: 1,
                  opacity: 1,
                }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{
                  duration: 1.5,
                  ease: "backInOut",
                }}
                src={TomatoWhite}
                alt=""
                loading="lazy"
              />
            </picture>
          </div>
        </div>
      ) : (
        width >= 1024 &&
        filteredPizzas[0].length > 0 &&
        filteredPizzas[1].length > 0 && (
          <div className="bottom-imgs-container flex justify-end w-full pt-14">
            <div className="tomato-white-container place-self-end">
              <picture className="tomato-white">
                <source srcSet={TomatoWhiteWebp} type="image/webp" />
                <source srcSet={TomatoWhite} type="image/png" />
                <motion.img
                  initial={{
                    opacity: 0,
                    scale: 0.5,
                  }}
                  whileInView={{
                    scale: 1,
                    opacity: 1,
                  }}
                  viewport={{ once: true, amount: 0.3 }}
                  transition={{
                    duration: 1.5,
                    ease: "backInOut",
                  }}
                  src={TomatoWhite}
                  alt=""
                  loading="lazy"
                />
              </picture>
            </div>
          </div>
        )
      )}
      {filteredPizzas[1].length > 0 && (
        <section className="kids-pizza-section flex flex-col">
          <div className="loulous-title grid">
            <h2 className="text-white uppercase">Pour les loulous</h2>
            <div className="bg-primary surprise-container flex justify-end items-center">
              <p className="text-white uppercase pr-1">+ une surprise</p>
            </div>
          </div>
          <div
            className={
              display === "mosaic"
                ? "mosaic-pizza-container grid grid-cols-2 gap-4"
                : "list-pizza-container flex flex-col "
            }
          >
            {filteredPizzas[1].map((pizza, i) => (
              <PizzaCard
                key={i}
                pizza={pizza}
                display={display}
                onClick={() => showDetails(pizza.slug)}
              />
            ))}
          </div>
        </section>
      )}
      {filteredPizzas[0].length === 0 && filteredPizzas[1].length === 0 && (
        <div className="no-pizzas">
          <h2 className="text-white uppercase">
            Il n'y a pas de pizzas correspondant à votre recherche
          </h2>
        </div>
      )}
      <PizzeriasSection width={width} />
      <ContactSection onClick={handleClick} />
    </>
  );
}
