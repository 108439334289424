import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./styles/_index.scss";
import { ConsentProvider } from "./context/ConsentContext";

const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ConsentProvider>
      <App />
    </ConsentProvider>
  </BrowserRouter>
);

serviceWorkerRegistration.register();
