import { useContext, useEffect, useState } from "react";
import ToggleBtn from "../../components/buttons/ToggleBtn";
import EventForm from "../../components/forms/EventForm";
import PublicForm from "../../components/forms/PublicForm";
import PizzeriasSection from "../../components/sections/PizzeriasSection";
import MetaTagsMaster from "../../components/utils/MetaTagsMaster";
import {
  ColorModeContext,
  ToggleContext,
} from "../../components/utils/Providers";

export default function Contact() {
  const { setColor } = useContext(ColorModeContext);
  const { toggle } = useContext(ToggleContext);

  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  useEffect(() => {
    setColor("black");
  }, []);

  return (
    <>
      <MetaTagsMaster
        title="Contact"
        description="Une question ou demande spécifique ? Nous serions ravies de répondre à vos questions ou couvrir vos événements."
        keywords="contact, information, événement, pizza cook, restaurant, france, saint sylvain, seiches, anjou"
      />
      <div className="main-contact-container text-white gap-20">
        <div className="container mx-auto">
          <div className="pb-[16px]">
            <h1 className="uppercase text-white pb-[32px]">Contact</h1>
            <p className="bold">Tenté par nos pizzas ? Envie de commander ?</p>
          </div>
          <div className="pb-[32px] flex gap-8">
            <div>
              <h3 className="pb-[8px]">Saint-Sylvain-D'Anjou</h3>
              <p className="text-white">
                16 rue Emmanuel Voisin <br />
                49480 Saint-Sylvain-D'Anjou
              </p>
              <a
                href="tel:+33241954053"
                className="bold text-white mt-[8px] block"
              >
                02 41 95 40 53
              </a>
              <br />
            </div>
            <div>
              <h3 className="pb-[8px]">Seiches-Sur-Le-Loir</h3>
              <p className="text-white">
                58 rue Nationale
                <br />
                49140 Seiches-Sur-Le-Loir
              </p>
              <a
                href="tel:+33241761082"
                className="bold text-white mt-[8px] block"
              >
                02 41 76 10 82
              </a>
            </div>
          </div>
          <p className="pb-16 text-white">
            Vous souhaitez nous contacter ou organiser un événement avec les
            services de Pizza Cook ? <br /> Merci de remplir le formulaire
            ci-dessous
          </p>
          {/* <div className="pb-16 md:flex gap-20">
            <div>
              <p className="bold pt-[32px] pb-[8px] text-xl">
                Commande en groupe ou en entreprise
              </p>
              <p>
                Merci de remplir le formulaire ci-dessous ou de prendre contact
                directement avec nous au 02 41 95 40 53.{" "}
              </p>
            </div>
            <div>
              <p className="bold pt-[32px] pb-[8px] text-xl">
                Vous organisez un événement et souhaitez faire appel aux
                services de Pizza Cook ?
              </p>
              <p>
                Merci de compléter le formulaire ci-dessous, en sélectionnant{" "}
                {""}
                <i>"Besoin de pizza pour votre événement ?"</i> nous prendrons
                rapidement contact avec vous pour plus d'informations.
              </p>
            </div>
          </div> */}
          <div className="contact-form">
            <div className={`flex gap-[10px] pb-8 items-center`}>
              <ToggleBtn
                className={
                  toggle
                    ? "toggle toggle-input [--tglbg:white] hover:bg-primary bg-primary"
                    : "toggle" +
                      " toggle-input [--tglbg:white] hover:bg-primary bg-base-100"
                }
                ariaLabel={
                  !toggle ? "contact" + " simple" : "contact événement"
                }
              />
              <p>Besoin de pizza pour votre événement ?</p>
            </div>
            {!toggle ? <PublicForm /> : <EventForm />}
          </div>
        </div>
      </div>
      <PizzeriasSection width={width} />
    </>
  );
}
