import { motion } from "framer-motion";
import PizzaGroupSmall from "../../assets/img/pizza-group-small.png";
import PizzaGroupSmallWebp from "../../assets/img/pizza-group-small.webp";
import PizzaGroup from "../../assets/img/pizza-group.png";
import PizzaGroupWebp from "../../assets/img/pizza-group.webp";
import ScooterGrey from "../../assets/img/scooter-grey.png";
import ScooterGreyWebp from "../../assets/img/scooter-grey.webp";
import WheatGrey from "../../assets/img/wheat-grey.png";
import WheatGreyWebp from "../../assets/img/wheat-grey.webp";
import MainBtn from "../buttons/MainBtn";

export default function GroupOrderSection({ onClick, width }) {
  const calculateYPosition = (width) => {
    if (width >= 1920) {
      return 120;
    } else if (width >= 1440) {
      return 70;
    } else {
      return 0;
    }
  };

  const yPosition = calculateYPosition(width);

  return (
    <section className="group-order-section">
      {width >= 768 && (
        <div className="flex justify-start overflow-hidden">
          <picture>
            <source srcSet={WheatGreyWebp} type="image/webp" />
            <source srcSet={WheatGrey} type="image/png" />
            <motion.img
              initial={{
                scale: 0.5,
                opacity: 0,
              }}
              whileInView={{
                scale: 1,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={WheatGrey}
              alt=""
              className="wheat-grey"
              loading="lazy"
            />
          </picture>
        </div>
      )}
      <div className="flex flex-col group-order-container-content text-base-100">
        <h2 className="uppercase">
          {width < 768
            ? "Une commande d'entreprise ?"
            : "Une commande de groupe ou en" + " entreprise ?"}
        </h2>
        <div className="">
          <p>
            Commandez vos pizzas et faites vous livrer* sur votre lieu
            d’entreprise dans un rayon de 15 km autour de Saint-Sylvain-d'Anjou.
          </p>
          <p className="text-sm">*Livraison sous conditions</p>
        </div>
        <MainBtn value="En savoir plus" onClick={onClick} name="group" />
        <div className="w-full flex justify-end sm:justify-center xl:justify-end z-10">
          <picture className="scooter-grey">
            <source srcSet={ScooterGreyWebp} type="image/webp" />
            <source srcSet={ScooterGrey} type="image/png" />
            <motion.img
              initial={{
                x: width >= 768 ? -400 : -250,
                y: yPosition,
                opacity: 0,
                rotate: -30,
              }}
              whileInView={{
                x: 0,
                opacity: 1,
                rotate: [0, -30, -30, 30, 0],
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 3.5,
                ease: "backInOut",
              }}
              src={ScooterGrey}
              alt=""
              loading="lazy"
            />
          </picture>
        </div>
      </div>
      <div className="flex justify-end h-full group-order-container-img-content">
        <div className="group-order-container-img grid">
          <picture className="pizza-group">
            <source srcSet={PizzaGroupWebp} type="image/webp" />
            <source srcSet={PizzaGroup} type="image/png" />
            <motion.img
              initial={{
                opacity: 0,
                x: 100,
              }}
              whileInView={{
                x: 0,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={PizzaGroup}
              alt="Table d'un restaurant"
              className="pizza-group"
              loading="lazy"
            />
          </picture>
          <picture className="pizza-group-small z-10">
            <source srcSet={PizzaGroupSmallWebp} type="image/webp" />
            <source srcSet={PizzaGroupSmall} type="image/png" />
            <motion.img
              initial={{
                opacity: 0,
                x: -100,
              }}
              whileInView={{
                x: 0,
                opacity: 1,
              }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{
                duration: 1.5,
                ease: "backInOut",
              }}
              src={PizzaGroupSmall}
              alt="Part de pizza"
              className="pizza-group-small z-10"
              loading="lazy"
            />
          </picture>
        </div>
      </div>
    </section>
  );
}
